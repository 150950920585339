import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/CMS/bannerSlice";
import { createBanner, updateBanner } from "../api";

const useBannerForm = ({ refetch, closeModal }) => {
  const dispatch = useDispatch();
  const profilefileInputRef = useRef(null);

  const { selectedItemsDetails, isEdit } = useSelector((state) => state.banner);

  const [fullImagePreview, setFullImagePreview] = useState(
    selectedItemsDetails !== "" ? selectedItemsDetails?.full_image?.url : ""
  );
  const [responsiveImagePreview, setResponsiveImagePreview] = useState(
    selectedItemsDetails !== "" ? selectedItemsDetails?.full_image?.url : ""
  );
  const [popupImagePreview, setPopupImagePreview] = useState(
    selectedItemsDetails !== "" ? selectedItemsDetails?.full_image?.url : ""
  );

  useEffect(() => {
    if (selectedItemsDetails !== "") {
      setFullImagePreview(selectedItemsDetails?.full_image?.url ?? "");
      setResponsiveImagePreview(
        selectedItemsDetails?.responsive_image?.url ?? ""
      );
      setPopupImagePreview(selectedItemsDetails?.popup_image?.url ?? "");
    }
  }, [selectedItemsDetails]);

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("*Name is required")
      .min(3, "Name must be at least 3 characters long")
      .matches(/^[^\s].*$/, "Name must not start with a space"),
    content: Yup.string().required("*Content is required")
      .min(3, "Content must be at least 3 characters long")
      .max(60, "Content must be at most 60 characters long")
      .matches(/^[^\s].*$/, "Content must not start with a space"),
    type: Yup.string().required("*Type is required"),
    full_image:Yup.mixed()
        .test("fileType", "Invalid file format", (value) => {
          if (value) {
            const supportedFormats = [
              "image/jpg", "image/jpeg", "image/png", "image/webp"
            ];
            return supportedFormats.includes(value[0].type);
          }
          return true;
        })
        .test("fileSize", "Please upload file less than 1.5MB", (value) => {
          if (value) {
            return value[0].size <= 1.5 * 1024 * 1024;
          }
          return true;
        })
        .test("requiredCheck", "*Banner is required", (value) => {
          // Only check required when not in edit mode
          if (!isEdit) {
            return !!value; // Ensure the value exists
          }
          return true; // Skip required validation in edit mode
        }),
      responsive_image: Yup.mixed()
        .test("fileType", "Invalid file format", (value) => {
          if (value) {
            const supportedFormats = [
         "image/jpg", "image/jpeg", "image/png", "image/webp"
            ];
            return supportedFormats.includes(value[0].type);
          }
          return true;
        })
        .test("fileSize", "Please upload file less than 1.5MB", (value) => {
          if (value) {
            return value[0].size <= 1.5 * 1024 * 1024;
          }
          return true;
        })
        .test("requiredCheck", "*Banner is required", (value) => {
          // Only check required when not in edit mode
          if (!isEdit) {
            return !!value; // Ensure the value exists
          }
          return true; // Skip required validation in edit mode
        }),
      popup_image:  Yup.mixed()
        .test("fileType", "Invalid file format", (value) => {
          if (value) {
            const supportedFormats = [
            "image/jpg", "image/jpeg", "image/png", "image/webp"
            ];
            return supportedFormats.includes(value[0].type);
          }
          return true;
        })
        .test("fileSize", "Please upload file less than 1.5MB", (value) => {
          if (value) {
            return value[0].size <= 1.5 * 1024 * 1024;
          }
          return true;
        })
        .test("requiredCheck", "*Banner is required", function(value) {
          // Only check required when not in edit mode
          const { type } = this.parent; // Access the type field    
          if (type === "1") {
            return true; // Skip validation if type is 2
          }
    
          if (!isEdit) {
            return !!value; // Ensure the value exists
          }
          return true; // Skip required validation in edit mode
        }),
  });

  const formik = useFormik({
    initialValues: {
      name: selectedItemsDetails?.name ?? "",
      content: selectedItemsDetails?.content ?? "",
      type: selectedItemsDetails?.type ?? "",
      full_image:"",
      responsive_image:  "",
      popup_image: "",
    },
    enableReinitialize: true,
    validationSchema,

    onSubmit: (values, { resetForm, setSubmitting }) => {
      if (isEdit) {
        let obj = {
          banner_id: selectedItemsDetails?._id,
          name: values.name,
          content: values.content,
          type: values?.type,
        };

          if (values.full_image?.[0]?.name ) {
            obj.full_image = values.full_image?.[0];
          }
          if (values.responsive_image?.[0]?.name) {
            obj.responsive_image = values.responsive_image?.[0];
          }
          if (values.popup_image?.[0]?.name) {
            obj.popup_image = values.popup_image?.[0];
          }

        let formData = new FormData();

        Object.keys(obj).forEach((key) => {
          return formData.append(key, obj[key]);
        });
        formData.append("_method", "PUT");
        updateBanner(formData).then((response) => {
          if (response?.data?.success) {
            toast.success("Banner updated successfully!");
            setSubmitting(false);
            refetch();
            handleCloseModal();
          } else if (response?.status === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
            setSubmitting(false);
          } else if (response?.success === false) {
            let errors = response?.message;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
            setSubmitting(false);
          } else {
            toast.error("Something went wrong");
            setSubmitting(false);
          }
        });
      } else {
        let obj = {
          name: values.name,
          content: values.content,
          type: values?.type,
        };

        if (values.full_image?.[0]?.name ) {
          obj.full_image = values.full_image?.[0];
        }
        if (
          values.responsive_image?.[0]?.name 
        ) {
          obj.responsive_image = values.responsive_image?.[0];
        }
        if (values.popup_image?.[0]?.name) {
          obj.popup_image = values.popup_image?.[0];
        }

        let formData = new FormData();

        Object.keys(obj).forEach((key) => {
          return formData.append(key, obj[key]);
        });
        createBanner(formData).then((response) => {
          if (response?.data?.success) {
            toast.success("Banner created successfully!");
            refetch();
            handleCloseModal();
            setSubmitting(false);
          } else if (response?.status === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
            setSubmitting(false);
          } else if (response?.success === false) {
            let errors = response?.message;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
            setSubmitting(false);
          } else {
            toast.error("Something went wrong");
            setSubmitting(false);
          }
        });
      }
    },
  });

  let typeOption = [
    { label: "Default", value: 1 },
    { label: "Offer", value: 2 },
  ];
  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const handleFullImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue("full_image", e?.target?.files);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setFullImagePreview(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      formik.setFieldError(
        "full_image",
        "The image must be less than 5MB in size."
      );
    }
  };
  const handleResponsiveImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 1.5) {
      formik.setFieldValue("responsive_image", e?.target?.files);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setResponsiveImagePreview(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 1.5) {
      toast.error("The image must be less than 1.5MB in size.");
      formik.setFieldError(
        "responsive_image",
        "The image must be less than 1.5MB in size."
      );
    }
  };
  const handlePopupImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 1.5) {
      formik.setFieldValue("popup_image", e?.target?.files);

      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setPopupImagePreview(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 1.5) {
      toast.error("The image must be less than 1.5MB in size.");
      formik.setFieldError(
        "popup_image",
        "The image must be less than 1.5MB in size."
      );
    }
  };

  return {
    typeOption,
    formik,
    fullImagePreview,
    responsiveImagePreview,
    popupImagePreview,
    selectedItemsDetails,
    profilefileInputRef,
    handlePopupImage,
    handleResponsiveImage,
    handleFullImage,
    handleCloseModal,
  };
};

export default useBannerForm;
